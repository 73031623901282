<template>
  <div id="reset-password-wrapper" class="d-flex justify-center align-center">
    <v-card
      min-width="350px"
      max-width="400px"
      class="elevation-0"
      style="position: relative"
    >
      <v-avatar id="reset-password-logo" size="90" class="elevation-1">
        <img :src="logo" alt="logo" style="width: 75px; height: 75px" />
      </v-avatar>
      <v-card-text class="text-center" style="padding: 50px 30px 20px 30px">
        <div class="font-weight-black mb-6" style="font-size: 20px">
          RESET PASSWORD
        </div>
        <v-row class="mt-4">
          <v-col cols="12">
            <v-text-field
              v-model="new_password"
              :append-icon="spNew ? 'mdi-eye' : 'mdi-eye-off'"
              :type="spNew ? 'text' : 'password'"
              :rules="[
                (v) => !!v || 'Password harus diisi',
                (v) => (!!v && v.length > 7) || 'Password minimal 8 karakter',
              ]"
              label="Password Baru"
              hide-details="auto"
              autocomplete
              outlined
              dense
              v-on:keyup.enter="validate"
              @click:append="spNew = !spNew"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="new_password_confirm"
              :append-icon="spConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="spConfirm ? 'text' : 'password'"
              :rules="[
                (v) => !!v || 'Konfirmasi password baru harus diisi',
                (v) => v == new_password || 'Password tidak sama',
              ]"
              label="Konfirmasi Password Baru"
              hide-details="auto"
              autocomplete
              outlined
              dense
              v-on:keyup.enter="validate"
              @click:append="spConfirm = !spConfirm"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              :disabled="loading"
              color="primary"
              text
              class="px-8 mt-2 mr-2"
              @click="$router.push('/login')"
              >Batal</v-btn
            >
            <v-btn
              :loading="loading"
              color="warning"
              depressed
              class="px-8 mt-2"
              @click="resetPassword"
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { userChangePasswordNoAuth } from "@/api/user";

import logo from "@/assets/logo.png";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      logo,
      new_password: "",
      new_password_confirm: "",
      spNew: false,
      spConfirm: false,
      successSent: false,
    };
  },
  mounted() {
    if (!this.$route.query.key) {
      this.$router.push("/login");
    }
  },
  methods: {
    resetPassword() {
      this.loading = true;
      userChangePasswordNoAuth({
        key: this.$route.query.key,
        new_password: this.new_password_confirm,
      })
        .then(({ data }) => {
          if (data.code) {
            this.$router.push("/login");
            this.$store.commit("SET_SNACKBAR", {
              value: true,
              text: "Berhasil merubah password silahkan login dengan password baru",
              color: "success",
            });
          } else {
            this.$store.commit("SET_SNACKBAR", {
              value: true,
              text: data.message,
              color: "error",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#reset-password-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  #reset-password-logo {
    background: white;
    position: absolute;
    left: 150px;
    top: -60px;
  }
}
</style>
