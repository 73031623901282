var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center align-center",attrs:{"id":"reset-password-wrapper"}},[_c('v-card',{staticClass:"elevation-0",staticStyle:{"position":"relative"},attrs:{"min-width":"350px","max-width":"400px"}},[_c('v-avatar',{staticClass:"elevation-1",attrs:{"id":"reset-password-logo","size":"90"}},[_c('img',{staticStyle:{"width":"75px","height":"75px"},attrs:{"src":_vm.logo,"alt":"logo"}})]),_c('v-card-text',{staticClass:"text-center",staticStyle:{"padding":"50px 30px 20px 30px"}},[_c('div',{staticClass:"font-weight-black mb-6",staticStyle:{"font-size":"20px"}},[_vm._v(" RESET PASSWORD ")]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.spNew ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.spNew ? 'text' : 'password',"rules":[
              (v) => !!v || 'Password harus diisi',
              (v) => (!!v && v.length > 7) || 'Password minimal 8 karakter',
            ],"label":"Password Baru","hide-details":"auto","autocomplete":"","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validate.apply(null, arguments)},"click:append":function($event){_vm.spNew = !_vm.spNew}},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.spConfirm ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.spConfirm ? 'text' : 'password',"rules":[
              (v) => !!v || 'Konfirmasi password baru harus diisi',
              (v) => v == _vm.new_password || 'Password tidak sama',
            ],"label":"Konfirmasi Password Baru","hide-details":"auto","autocomplete":"","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validate.apply(null, arguments)},"click:append":function($event){_vm.spConfirm = !_vm.spConfirm}},model:{value:(_vm.new_password_confirm),callback:function ($$v) {_vm.new_password_confirm=$$v},expression:"new_password_confirm"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-8 mt-2 mr-2",attrs:{"disabled":_vm.loading,"color":"primary","text":""},on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("Batal")]),_c('v-btn',{staticClass:"px-8 mt-2",attrs:{"loading":_vm.loading,"color":"warning","depressed":""},on:{"click":_vm.resetPassword}},[_vm._v("Simpan")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }